import http, { setJWT } from "./http";
import MapService from "./map.service";
import Promisable from "./promisable.service";

let save,
  invoice,
  open = true,
  draft = false,
  filters = {
    date: [],
    driver: [],
    status: [],
    invoiceId: "",
  };
const API_URL = "/user";

export const getOpenInvoices = () => open;
export const getDraftInvoices = () => draft;

export const setOpenInvoices = (val) => (open = val);
export const setDraftInvoices = (val) => (draft = val);

export const getSaveInvoice = () => save;
export const setSaveInvoice = (val) => (save = val);

export const getInvoiceFilters = () => filters;
export const setInvoiceDateFilter = (date) => {
  filters = { ...filters, date, invoiceId: "" };
};
export const setInvoiceDriverFilter = (driver) => {
  filters = { ...filters, driver, invoiceId: "" };
};
export const setInvoiceStatusFilter = (status) => {
  filters = { ...filters, status, invoiceId: "" };
};
export const setInvoiceIdFilter = (invoiceId) => {
  filters = { invoiceId, date: [], driver: [], status: [] };
};
export const resetInvoiceFilters = () => {
  filters = {
    date: [],
    driver: [],
    status: [],
    invoiceId: "",
  };
};

const invoiceService = {
  wait: async () => {
    return new Promise((resolve, reject) => {
      const time = setInterval(() => {
        if (!MapService.isWaiting()) {
          clearInterval(time);
          resolve("");
        }
      }, 500);
    });
  },

  getUpdateInvoice: () => invoice,
  setUpdateInvoice: (data) => (invoice = data),
  getUpdateInvoiceId: () => invoice?.invoice_id,

  addInvoice: async (data) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    date = dt + "." + month + "." + year;
    data.current_date = date;

    let invoice_details = [];

    await invoiceService.wait();
    if (
      data.invoice_details?.length > 0 &&
      Object.keys(data.invoice_details[0]).length > 0
    ) {
      for (let detail of data.invoice_details) {
        let place_id =
          detail.pickup && MapService.get(detail.pickup.replace(/\s+/g, ""));
        let des_id =
          detail.destination &&
          MapService.get(detail.destination.replace(/\s+/g, ""));
        let key =
          detail.pickup?.replace(/\s+/g, "") +
          detail.destination?.replace(/\s+/g, "");
        let response = key && MapService.get(key);
        detail.hours = detail.hourly ? detail.hours : null;
        detail.pickup_id = place_id;
        detail.destination_id = des_id;
        detail.total_distance = response;

        invoice_details.push(detail);
      }
    }
    setJWT();
    return http.post(API_URL + "/addInvoice", {
      company_name: data.company_name,
      person_name: data.person_name,
      address: data.address,
      postal_code: data.postal_code,
      city: data.city,
      email: data.email,
      invoice_details:
        data.invoice_details?.length > 0 &&
        Object.keys(data.invoice_details[0]).length > 0
          ? data.invoice_details
          : null,
      brutto: data.brutto,
      netto: data.netto,
      mwst: data.mwst,
      status: data.status,
      payed_by: data.payed_by,
      current_date: data.current_date,
      international: data.international,
      draft: data.draft,
      invoice_creation_date: new Date(),
    });
  },

  updateInvoice: async (data) => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    date = dt + "." + month + "." + year;
    data.current_date = date;
    if (!data.draft && !data.invoice_id) {
      data.invoice_creation_date = new Date();
    }

    let invoice_details = [];
    await invoiceService.wait();
    if (
      data.invoice_details?.length > 0 &&
      Object.keys(data.invoice_details[0]).length > 0
    ) {
      for (let detail of data.invoice_details) {
        let place_id =
          detail.pickup && MapService.get(detail.pickup.replace(/\s+/g, ""));
        let des_id =
          detail.destination &&
          MapService.get(detail.destination.replace(/\s+/g, ""));
        let key =
          detail.pickup?.replace(/\s+/g, "") +
          detail.destination?.replace(/\s+/g, "");
        let response = key && MapService.get(key);
        detail.hours = detail.hourly ? detail.hours : null;
        detail.pickup_id = place_id;
        detail.destination_id = des_id;
        detail.total_distance = response;

        invoice_details.push(detail);
      }

      data.invoice_details = invoice_details;
    } else data.invoice_details = null;

    setJWT();
    return http.post(API_URL + "/updateInvoice", data);
  },

  getInvoiceForUser: () => {
    let data = { open: getOpenInvoices() };
    if (getDraftInvoices()) data = { open: false, draft: true };
    setJWT();
    return http.post(API_URL + "/getInvoices", data);
  },
  deleteInvoice: (_id) => {
    setJWT();
    return http.post("/admin/deleteInvoice", { invoice_id: _id });
  },

  exportInvoices: async (start_date, end_date, setLoading, setExport) => {
    let data = { start_date, end_date };
    setJWT();
    const [success, error] = await Promisable.asPromise(
      http.post("/admin/zipinvoices", data)
    );
    if (success) {
      setLoading(false);
      setExport(false);
    }
    return [success, error];
  },
};

export default invoiceService;
