import authService from "../../_services/auth.service";
import ToasterService from "../../_services/toasterService";
import { SET_MESSAGE } from "../message/messageTypes";
import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
} from "./authTypes";

export const register = (data) => (dispatch) => {
	return authService.register(data).then(
		(response) => {
			dispatch({
				type: REGISTER_SUCCESS,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: response.data.message,
			});

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: REGISTER_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: message,
			});

			return Promise.reject();
		}
	);
};

export const login = (email, password) => (dispatch) => {
	return authService.login(email, password).then(
		(data) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.data.user },
			});

			ToasterService.showSuccessToaster("Logged in successfully");

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: LOGIN_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: message,
			});

			return Promise.reject();
		}
	);
};

export const logout = () => (dispatch) => {
	authService.logout();
	dispatch({
		type: LOGOUT,
	});
};

export const changePassword = (password) => (dispatch) => {
	return authService.changePassword(password).then(
		(data) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: { user: data.data.user },
			});

			ToasterService.showSuccessToaster(
				"Password updated successfully"
			);

			return Promise.resolve();
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: LOGIN_FAIL,
			});

			dispatch({
				type: SET_MESSAGE,
				payload: message,
			});

			return Promise.reject();
		}
	);
};
