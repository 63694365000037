import cashPaidService from "../../_services/cashPaid.service";
import ToasterService from "../../_services/toasterService";
import { SET_MESSAGE } from "../message/messageTypes";
import {
  ADD_CASH_PAID,
  GET_CASH_PAID,
  UPDATE_CASH_PAID,
  DELETE_CASH_PAID,
  TOTAL_CASH_PAID,
} from "./cashPaidType";

export const addCashPaid = (data) => (dispatch) => {
  return cashPaidService.addCashPaid(data).then(
    (response) => {
      dispatch({
        type: ADD_CASH_PAID,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("CashPaid added successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getCashPaid = (data) => (dispatch) => {
  return cashPaidService.getCashPaid(data).then(
    (response) => {
      dispatch({
        type: GET_CASH_PAID,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_CASH_PAID,
        payload: { data: { cashPaid: [] } },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateCashPaid = (data) => (dispatch) => {
  return cashPaidService.updateCashPaid(data).then(
    (response) => {
      dispatch({
        type: UPDATE_CASH_PAID,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Cash Paid updated successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const deleteCashPaid = (data) => (dispatch) => {
  return cashPaidService.deleteCashPaid({ _id: data }).then(
    (response) => {
      dispatch({
        type: DELETE_CASH_PAID,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("CashPaid deleted successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getTotalCashPaid = (data) => (dispatch) => {
  return cashPaidService.totalCashPaid(data).then(
    (response) => {
      dispatch({
        type: TOTAL_CASH_PAID,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: TOTAL_CASH_PAID,
        payload: error.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
