import {
  ADD_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT,
  UPDATE_CLIENT,
} from "./clientType";

const initialState = {
  clients: [],
  private_client: null,
};

const clientReducer = (state = initialState, action) => {
  let index, allClients;
  switch (action.type) {
    case ADD_CLIENT:
      allClients = [...state.clients];
      if (allClients?.[0] === null) allClients = [];
      allClients.unshift(action.payload.data.client);

      return {
        ...state,
        clients: allClients,
      };
    case GET_CLIENT:
      return {
        ...state,
        private_client: action.payload.data.private_client,
        clients:
          action.payload.data.clients.length > 0
            ? action.payload.data.clients
            : [null],
      };
    case UPDATE_CLIENT:
      if (action.payload.data.client._id === state?.private_client?._id)
        return {
          ...state,
          private_client: action.payload.data.client,
        };

      allClients = [...state.clients];
      index = allClients.findIndex(
        (row) => row._id === action.payload.data.client._id
      );
      allClients[index] = action.payload.data.client;
      return {
        ...state,
        clients: allClients,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((item) => item._id !== action.payload),
      };
    default:
      return state;
  }
};

export default clientReducer;
