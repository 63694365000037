import { change } from "redux-form";
import { useHistory, withRouter } from "react-router";

import vehicleService from "../../../../../_services/vehicle.service";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import expenseService from "../../../../../_services/expense.service";

let UpdateExpenseForm = ({ id }) => {
  const isSet = useRef(false);
  const dispatch = useDispatch();
  const expense = useSelector((state) => state.expense.expense);
  const history = useHistory();

  useEffect(() => {
    if (isSet.current) return;

    const index = expenseService.getIndex();
    if (isNaN(index)) return history.push("/expense");

    const data = expense?.[index];
    if (!data) return history.push("/expense");

    const { date, name, price, reciept, driverId } = data;



    let fueltype = [
      { label: "Fuel", id: "fuel" },
      { label: "Wash", id: "wash" },
      { label: "Water", id: "water" },
      { label: "Parking", id: "parking" },
      { label: "Other", id: "other" },
    ].filter(({ id }) => id === name);

    if (fueltype.length === 0) {
      fueltype = [{ label: "Other", id: "other" }];
      dispatch(change("AddExpenseForm", "other", name));
    }
    dispatch(change("AddExpenseForm", "date", date));
    dispatch(change("AddExpenseForm", "fueltype", fueltype));
    dispatch(change("AddExpenseForm", "price", price));
    dispatch(change("AddExpenseForm", "reciept", reciept));

    dispatch(
      change("AddExpenseForm", "driver", [
        { id: driverId._id, label: driverId.first_name },
      ])
    );

    isSet.current = true;
  }, [expense, history, dispatch]);

  return <></>;
};

export default withRouter(UpdateExpenseForm);
