import ToasterService from "../../_services/toasterService";
import vehicleService from "../../_services/vehicle.service";
import { SET_MESSAGE } from "../message/messageTypes";
import {
  ADD_VEHICLE,
  DELETE_VEHICLE,
  GET_VEHICLES,
  UPDATE_VEHICLE,
} from "./vehicleType";

export const addVehicle = (data) => (dispatch) => {
  return vehicleService.addVehicles(data).then(
    (response) => {
      dispatch({
        type: ADD_VEHICLE,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Vehicle added successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getVehicles = (data) => (dispatch) => {
  return vehicleService.getVehicles(data).then(
    (response) => {
      dispatch({
        type: GET_VEHICLES,
        payload: response.data.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_VEHICLES,
        payload: { data: { vehicles: [] } },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateVehicle = (data) => (dispatch) => {
  return vehicleService.updateVehicle(data).then(
    (response) => {
      dispatch({
        type: UPDATE_VEHICLE,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Vehicle updated successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const deleteVehicle = (data) => (dispatch) => {
  return vehicleService.deleteVehicle({ _id: data }).then(
    (response) => {
      dispatch({
        type: DELETE_VEHICLE,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Vehicle deleted successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
