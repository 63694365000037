import moment from "moment";
import authService from "./auth.service";
import ToasterService from "./toasterService";
import { config } from "../config";

let Session = class SessionService {
  constructor() {
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.warnTimeout = null;
    this.logoutTimeout = null;

    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimeout);
    });
    this.init();
  }

  clearTimeout1() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  init() {
    let time = localStorage.getItem("time");

    if (!time) {
      let date = new Date().toISOString();
      localStorage.setItem("time", date);
    }

    this.warnTimeout = setTimeout(
      this.warn,
      (config.INACTIVITY_TIME - 60) * 1000
    );

    this.logoutTimeout = setTimeout(this.logout, config.INACTIVITY_TIME * 1000);
  }

  resetTimeout() {
    let time = localStorage.getItem("time");

    if (time == null) {
      this.destroy();
      return;
    }
    if (time) {
      var start = moment(time);
      var end = moment(new Date().toISOString());
      let minutes = end.diff(start, "seconds");

      if (minutes > config.INACTIVITY_TIME) {
        this.destroy();
      } else {
        localStorage.setItem("time", new Date().toISOString());
        this.clearTimeout1();
        this.init();
        this.sendActivity();
      }
    }
  }

  static setLatestServerCall() {
    localStorage.setItem("sTime", new Date().toISOString());
  }
  warn() {
    ToasterService.showErrorToaster(`You'll be logout soon due to inactivity`);
  }
  sendActivity() {
    let time = localStorage.getItem("sTime");
    var start = moment(time);
    var end = moment(new Date().toISOString());
    let minutes = end.diff(start, "seconds");
    if (config.INACTIVITY_TIME - minutes <= 30) {
      localStorage.setItem("sTime", new Date().toISOString());
      authService.sendActivity();
    }
  }
  logout() {
    this.destroy();
    authService.logout();
    setTimeout(() => {
      window.location.assign("/");
    }, 300);
  }

  destroy() {
    this.clearTimeout1();
    localStorage.removeItem("time");
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimeout);
    });
  }
};

export default Session;
