
import { change } from "redux-form";
import { useHistory, withRouter } from "react-router";

import vehicleService from "../../../../../_services/vehicle.service";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

let UpdateVehicleForm = ({ id }) => {
	const isSet = useRef(false);
	const dispatch = useDispatch();
	const vehicle = useSelector((state) => state.vehicle.vehicles);
	const history = useHistory()


	useEffect(() => {
		if (isSet.current) return;

		const index = vehicleService.getIndex();
		if (isNaN(index)) return history.push("/vehicle");

		const data = vehicle?.[index];
		if (!data) return history.push("/vehicle");

		const {
			plate,
			company_id,
			concession_deed,
			liability_insurance,
			vehicle_registration,
			documents,

		} = data;

		dispatch(change("AddVehicleForm", "plate", plate));
		dispatch(
			change("AddVehicleForm", "company", [
				{ label: company_id.name, id: company_id._id },
			])
		);
		dispatch(change("AddVehicleForm", "concession_deed", concession_deed));
		dispatch(change("AddVehicleForm", "liability_insurance", liability_insurance));
		dispatch(change("AddVehicleForm", "vehicle_registration", vehicle_registration));
		dispatch(change("AddVehicleForm", "documents", documents));



		isSet.current = true;
	}, [vehicle,  history, dispatch]);

	return <></>

};



export default withRouter(UpdateVehicleForm);

