export const ADD_INVOICE = "ADD_INVOICE";
export const GET_INVOICE = "GET_INVOICE";
export const EMAIL_NOTIFY = "EMAIL_NOTIFY";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const INCREMENT_PAGE = "INCREMENT_PAGE";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const ERROR_INVOICES = "ERROR_INVOICES";
export const GET_INVOICE_ADMIN = "GET_INVOICE_ADMIN";
export const REMOVE_INVOICE = "REMOVE_INVOICE";
