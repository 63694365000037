import { useState, useEffect } from "react";
import { Input as BaseInput } from "baseui/input";

const Input = (props) => {
	const [isValid, setIsValid] = useState(false);
	const [isVisited, setIsVisited] = useState(false);
	const shouldShowError = isValid && isVisited;

	useEffect(() => {
		setIsValid(props?.meta?.invalid);
		setIsVisited(props?.meta?.touched);
	}, [props]);

	return (
		<div>
			<BaseInput
				size="compact"
				autoComplete="current-password"
				{...props}
				{...props.input}
				error={shouldShowError}
				onBlur={(e) => {
					props?.handleBlur?.(e);
					e.preventDefault();
				}}
				onFocus={(e) => {
					props?.handleFocus?.(e);
					e.preventDefault();
				}}
				overrides={{
					StartEnhancer: { style: { paddingLeft: "0px", paddingRight: "0px" } },
				}}
			/>
			{shouldShowError && (
				<div style={{ fontSize: "14px", color: "red", marginTop: "5px" }}>
					* {props.meta.error}
				</div>
			)}
		</div>
	);
};

export default Input;
