import rootReducer from "./rootReducer";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const composedEnhancer = composeWithDevTools(
	applyMiddleware(thunkMiddleware)
);

const store = createStore(rootReducer, composedEnhancer);

export default store;
