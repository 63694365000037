import companyService from "../../_services/company.service";
import ToasterService from "../../_services/toasterService";
import { SET_MESSAGE } from "../message/messageTypes";
import {
  ADD_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES,
  UPDATE_COMPANY,
} from "./companyType";

export const addCompany = (data) => (dispatch) => {
  return companyService.addCompany(data).then(
    (response) => {
      dispatch({
        type: ADD_COMPANY,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Company added successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const getCompanies = () => (dispatch) => {
  return companyService.getCompanies().then(
    (response) => {
      dispatch({
        type: GET_COMPANIES,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_COMPANIES,
        payload: { data: { companies: [] } },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const updateCompany = (data) => (dispatch) => {
  return companyService.updateCompany(data).then(
    (response) => {
      dispatch({
        type: UPDATE_COMPANY,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Company updated successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const deleteCompany = (data) => (dispatch) => {
  return companyService.deleteCompany({ id: data }).then(
    (response) => {
      dispatch({
        type: DELETE_COMPANY,
        payload: data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Company deleted successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
