import { useEffect, useState } from "react";
import pdf from "../_assets/pdf.png";
import download from "../_assets/download.png";
import { FileUploader } from "baseui/file-uploader";
import adminService from "../_services/admin.service";
import ToasterService from "../_services/toasterService";
import { useSelector } from "react-redux";
import CircleLoader from "./CircleLoader";

export default function FileUpload(props) {
  const [name, setName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imgUrl, setImageUrl] = useState(null);

  let image = useSelector(
    (state) => state.form[props.form]?.values?.[props.name]
  );

  useEffect(() => {
    setImageUrl(image);
    setName(image ? image.substr(42) : "");
  }, [image]);

  return (
    <div style={{ position: "relative" }}>
      {uploading && <CircleLoader />}
      {!imgUrl ? (
        <FileUploader
          multiple={false}
          maxSize={6000000}
          accept={props.accept}
          onDrop={(acceptedFiles, rejectedFiles) => {
            props.imageUploadStart(props.name);

            if (rejectedFiles.length > 0) {
              props.imageUploadEnd("", props.name);
              if (rejectedFiles[0].size > 5000000)
                return ToasterService.showErrorToaster("Max file size is 5MB!");

              let types = rejectedFiles[0].type.split("/");
              let type = "." + types[types.length - 1];

              if (!props.accept.includes(type))
                return ToasterService.showErrorToaster(
                  `Type ${type} not allowed. Please choose correct format!`
                );
            }

            let formData = new FormData();
            formData.append("image", acceptedFiles[0]);
            setUploading(true);
            adminService
              .imageUpload(formData)
              .then((response) => {
                setUploading(false);
                props.imageUploadEnd(response.data.data.url, props.name);
              })
              .catch((err) => {
                setUploading(false);
                ToasterService.showErrorToaster(err.message);
                props.imageUploadEnd("", props.name);
              });
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            padding: "15px",
            borderRadius: "12px",
            background: "#f6f6f6",
            border: "2px dashed #cbcbcb",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={imgUrl}
              style={{
                position: "absolute",
                top: "-10px",
                right: "20px",
                cursor: "pointer",
                background: "white",
                boxShadow: "0 0 5px 1px #cdcdcd",
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                lineHeight: "20px",
                textAlign: "center",
                fontSize: "10px",
                color: "red",
                textDecoration: "none",
              }}
            >
              <img
                src={download}
                alt="download"
                style={{ width: "10px", verticalAlign: "middle" }}
              />
            </a>
            <span
              onClick={() => props.imageUploadEnd("", props.name)}
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                cursor: "pointer",
                background: "white",
                boxShadow: "0 0 5px 1px #cdcdcd",
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                lineHeight: "20px",
                textAlign: "center",
                fontSize: "10px",
                color: "red",
              }}
            >
              X
            </span>

            {name?.endsWith(".pdf") ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={pdf}
                  alt="pdf"
                  width="25px"
                  height="25px"
                  style={{ margin: "5px" }}
                />
                <div
                  style={{
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    wordBreak: "break-all",
                    display: "-webkit-box",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {name}
                </div>
              </div>
            ) : (
              <img
                alt={name
                  .replace(".png", "")
                  .replace(".jpg", "")
                  .replace(".jpeg", "")}
                src={imgUrl}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
