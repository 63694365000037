import { FormControl } from "baseui/form-control";
import { Input as BaseInput } from "baseui/input";

export default function InputRedux({
	input,
	label,
	children,
	handleBlur,
	handleFocus,
	meta: { error, touched },
	...rest
}) {
	return (
		<FormControl
			label={label}
			overrides={{ Label: { style: { marginBottom: "4px" } } }}
			caption={
				touched &&
				error && (
					<div style={{ fontSize: "14px", color: "red", marginTop: "5px" }}>
						* {error}
					</div>
				)
			}
		>
			<BaseInput
				size="compact"
				error={touched && error}
				autoComplete="current-password"
				overrides={{
					StartEnhancer: { style: { paddingLeft: "0px", paddingRight: "0px" } },
				}}
				{...rest}
				{...input}
				onBlur={(e) => {
					handleBlur?.(e);
					e.preventDefault();
				}}
				onFocus={(e) => {
					handleFocus?.(e);
					e.preventDefault();
				}}
			/>
		</FormControl>
	);
}
