import driverService from "../../_services/driver.service";
import { ADD_DRIVER, GET_DRIVER, UPDATE_DRIVER } from "./driverType";

const initialState = {
	drivers: [],
};

const driverReducer = (state = initialState, action) => {
	let index, allDrivers;
	switch (action.type) {
		case ADD_DRIVER:
			allDrivers = [...state.drivers];
			if (allDrivers?.[0] === null) allDrivers = [];
			allDrivers.unshift(action.payload.data.user);

			return {
				...state,
				drivers: allDrivers,
			};
		case UPDATE_DRIVER:
			allDrivers = [...state.drivers];
			index = driverService.getIndex();
			allDrivers[index] = action.payload.data.driver;

			return {
				...state,
				drivers: allDrivers,
			};
			
		case GET_DRIVER:
			return {
				...state,
				drivers:
					action.payload.data.length > 0
						? action.payload.data
						: [null],
			};
		default:
			return state;
	}
};
export default driverReducer;
