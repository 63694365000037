import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";

export default function SelectRedux({
	input,
	label,
	children,
	meta: { touched, error },
	...custom
}) {
	return (
		<FormControl
			label={label}
			overrides={{ Label: { style: { marginBottom: "4px" } } }}
			caption={
				touched &&
				error && (
					<div style={{ fontSize: "14px", color: "red", marginTop: "5px" }}>
						* {error}
					</div>
				)
			}
		>
			<Select
				{...input}
				{...custom}
				onBlur={() => {}}
				backspaceRemoves={false}
				error={touched && error}
				onChange={({ value }) => input.onChange(value)}
				overrides={{ Dropdown: { style: { maxHeight: "300px" } } }}
			/>
		</FormControl>
	);
}
