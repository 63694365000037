import { useEffect, useState } from "react";

import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { requiredSelect } from "../../../_common/validate";
import RenderSelectField from "../../../_common/SelectRedux";

export default function SelectDriver() {
  const [driverList, setDriverList] = useState([]);
  const drivers = useSelector((state) => state.drivers.drivers);

  useEffect(() => {
    if (drivers?.[0] === null) return;

    let arr = [];

    drivers.forEach(({ _id, first_name, last_name }) =>
      arr.push({ id: _id, label: first_name + " " + last_name })
    );

    setDriverList(arr);
  }, [drivers]);

  return (
    <Field
      size="compact"
      name="driver"
      label="Select Driver"
      options={driverList}
      validate={[requiredSelect]}
      placeholder="Select Driver"
      component={RenderSelectField}
    />
  );
}
