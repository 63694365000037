import { useEffect, useState } from "react";

import { Field } from "redux-form";
import { useSelector } from "react-redux";
import { requiredSelect } from "../../../_common/validate";
import RenderSelectField from "../../../_common/SelectRedux";

export default function SelectCompany({ validate }) {
  const [companyList, setCompanyList] = useState([]);
  const companies = useSelector((state) => state.company.companies);

  useEffect(() => {
    if (companies?.[0] === null) return;

    let arr = [];

    companies.forEach(({ _id, name }) => arr.push({ id: _id, label: name }));

    setCompanyList(arr);
  }, [companies]);

  return (
    <Field
      size="compact"
      name="company"
      label="Company"
      options={companyList}
      validate={[requiredSelect]}
      placeholder="Select company"
      component={RenderSelectField}
    />
  );
}
