import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import CashPaid from "../components/admin/CashPaid/CashPaid";
import AddCashPaid from "../components/admin/CashPaid/CashPaidForms/AddCashPaid/AddCashPaid";
import Expense from "../components/admin/Expense/Expense";
import AddExpense from "../components/admin/Expense/ExpenseForms/addExpense/addExpense";
import Vehicle from "../components/admin/Vehicle/Vehicle";
import AddVehicle from "../components/admin/Vehicle/Vehicle/addVehicle/AddVehicle";
import Header from "../components/layout/Header";
import CircleLoader from "../_common/CircleLoader";
import AdminPrivateRoute from "./AdminPrivateRoute";
import DriverPrivateRoute from "./DriverPrivateRoute";
import PrivateRoute from "./PrivateRoute";

const Login = lazy(() => import("../components/auth/Login/Login"));
const Issues = lazy(() => import("../components/admin/issue/Issues"));
const Users = lazy(() => import("../components/admin/users/Users"));
const Dashboard = lazy(() => import("../components/driver/Dashboard"));
const Admin = lazy(() => import("../components/admin/invoices/Admin"));
const AddInvoice = lazy(() => import("../_common/invoice/AddInvoice"));
const Careers = lazy(() => import("../components/admin/career/Careers"));
const Drivers = lazy(() => import("../components/admin/drivers/Drivers"));
const Company = lazy(() => import("../components/admin/company/Company"));
const Register = lazy(() => import("../components/auth/Register/Register"));
const Partners = lazy(() => import("../components/admin/partner/Partners"));
const Earnings = lazy(() => import("../components/admin/earnings/Earnings"));
const PrivateEarnings = lazy(() =>
  import("../components/admin/earnings/PrivateEarnings")
);
const AddEarnings = lazy(() =>
  import("../components/admin/earnings/addEarnings/AddEarnings")
);
const Clients = lazy(() => import("../components/admin/client/Clients"));

export default function Routes() {
  return (
    <Router>
      <Header />
      <Suspense fallback={<CircleLoader />}>
        <Switch>
          {/**************************** Driver Routes ***************************/}
          <DriverPrivateRoute exact path="/dashboard">
            <Dashboard />
          </DriverPrivateRoute>
          {/**************************** Admin Routes ***************************/}
          <AdminPrivateRoute exact path="/register">
            <Register />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/update-driver/:id">
            <Register />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/invoices">
            <Admin />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/users">
            <Users />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/drivers">
            <Drivers />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/partners">
            <Partners />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/company">
            <Company />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/careers">
            <Careers />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/private-earnings">
            <PrivateEarnings />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/earnings">
            <Earnings />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/add-earnings">
            <AddEarnings />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/update-earnings/:id">
            <AddEarnings />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/vehicle">
            <Vehicle />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/add-vehicle">
            <AddVehicle />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/update-vehicle/:id">
            <AddVehicle />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/cash-paid">
            <CashPaid />
          </AdminPrivateRoute>

          <AdminPrivateRoute exact path="/add-cashPaid">
            <AddCashPaid />
          </AdminPrivateRoute>
          <AdminPrivateRoute exact path="/update-cashPaid/:id">
            <AddCashPaid />
          </AdminPrivateRoute>

          {/**************************** Private Routes ***************************/}
          <PrivateRoute exact path="/clients">
            <Clients />
          </PrivateRoute>
          <PrivateRoute exact path="/add-invoice">
            <AddInvoice />
          </PrivateRoute>
          <PrivateRoute exact path={`/update-invoice/:id`}>
            <AddInvoice />
          </PrivateRoute>
          <PrivateRoute exact path="/issues">
            <Issues />
          </PrivateRoute>
          <PrivateRoute exact path="/expense">
            <Expense />
          </PrivateRoute>
          <PrivateRoute exact path="/add-expense">
            <AddExpense />
          </PrivateRoute>
          <PrivateRoute exact path="/update-expense/:id">
            <AddExpense />
          </PrivateRoute>
          <Route exact path="/" component={Login} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}
