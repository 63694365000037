import {
  ADD_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES,
  UPDATE_COMPANY,
} from "./companyType";

const initialState = {
  companies: [],
};

const companyReducer = (state = initialState, action) => {
  let index, allCompanies;
  switch (action.type) {
    case ADD_COMPANY:
      allCompanies = [...state.companies];
      if (allCompanies?.[0] === null) allCompanies = [];
      allCompanies.unshift(action.payload.data.company);

      return {
        ...state,
        companies: allCompanies,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies:
          action.payload.data.companies.length > 0
            ? action.payload.data.companies
            : [null],
      };
    case UPDATE_COMPANY:
      allCompanies = [...state.companies];
      index = allCompanies.findIndex(
        (row) => row._id === action.payload.data.company._id
      );
      allCompanies[index] = action.payload.data.company;
      return {
        ...state,
        companies: allCompanies,
      };
    case DELETE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter(
          (item) => item._id !== action.payload
        ),
      };
    default:
      return state;
  }
};
export default companyReducer;
