import "./App.css";
import Routes from "./routes/routes";
import Toaster from "./_common/toaster";
import { LightTheme, BaseProvider } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";

const engine = new Styletron();

function App() {
	return (
		<StyletronProvider value={engine}>
			<BaseProvider theme={LightTheme}>
				<Toaster />
				<Routes />
			</BaseProvider>
		</StyletronProvider>
	);
}

export default App;
