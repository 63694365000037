import http, { setJWT } from "./http";
let index;

const cashPaidService = {
  getIndex: () => index,
  setIndex: (id) => (index = id),

  getCashPaid: (data) => {
    setJWT();
    return http.post("/admin/getcash", data);
  },

  addCashPaid: (data) => {
    return http.post("/admin/cashpaid", data);
  },
  deleteCashPaid: (data) => {

    setJWT();
    return http.post("/admin/deletecash", data);
  },

  updateCashPaid: (data) => {
    return http.post("/admin/updatecash", data);
  },
  totalCashPaid: (data) => {
    return http.post("/admin/totalcash", data);
  },
};

export default cashPaidService;
