import http, { setJWT } from "./http";
import authService from "./auth.service";

const companyService = {
  getCompanies: () => {
    setJWT();
    return http.get(authService.apiURL() + "/getCompanies");
  },

  addCompany: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/addCompany", data);
  },

  updateCompany: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/updateCompany", data);
  },

  deleteCompany: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/deleteCompany", data);
  },
};

export default companyService;
