import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../../../_common/TableLoader";
import { Button } from "baseui/button";
import CircleLoader from "../../../_common/CircleLoader";
import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { getCompanies } from "../../../redux/company/companyAction";
import "./vehicle.css";
import {
  Modal,
  FocusOnce,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalButton,
} from "baseui/modal";
import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import BannerHeading from "../../../_common/BannerHeading";
import { Link, useHistory } from "react-router-dom";
import { Select } from "baseui/select";
import vehicleService from "../../../_services/vehicle.service";
import {
  deleteVehicle,
  getVehicles,
} from "../../../redux/vehicle/vehicleAction";
import Pdf from "../../../_assets/pdf2.svg";
import Img from "../../../_assets/img.svg";
import Document from "../../../_assets/document.svg";
import CustomPagination from "../../../_common/CustomPagination";

export default function Vehicle() {
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]);
  const [searched, setSearched] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [plate, setplate] = useState();
  const [isLoading, setLoading] = useState(false);
  const [vehicleDel, setVehicleDel] = useState(false);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const companies = useSelector((state) => state.company.companies);
  const { vehicles: vehicle, count } = useSelector((state) => state.vehicle);

  useEffect(async () => {
    let data = { page };
    setLoading(true);

    if (company[0]) {
      data.company_id = company[0]?.id;
    }
    await dispatch(getVehicles(data));
    setSearched(false);
    setLoading(false);
  }, [company, dispatch, page]);

  useEffect(() => dispatch(getCompanies()), [dispatch]);

  useEffect(() => {
    if (companies?.[0] === null) return;

    let newArr = [];
    companies.forEach(({ _id, name }) => newArr.push({ id: _id, label: name }));

    setCompaniesList(newArr);
  }, [companies]);

  const onChangeCompany = (value) => {
    setPage(1);
    setSearched(true);
    setCompany(value);
  };

  const handleDelete = async () => {
    setLoading(true);
    await dispatch(deleteVehicle(plate));

    setVehicleDel("");
    setLoading(false);
  };

  const checkSubstringsCaseInsensitive = (mainString, substrings) => {
    const lowercaseMainString = mainString.toLowerCase();
    const lowercaseSubstrings = substrings.map((substring) =>
      substring.toLowerCase()
    );
    return lowercaseSubstrings.some((substring) =>
      lowercaseMainString.includes(substring)
    );
  };

  return (
    <>
      <div className="dashboard-main">
        <Grid align={ALIGNMENT.center}>
          <Cell span={[4, 8, 12]}>
            <BannerHeading
              title={`Vehicle`}
              text="Add Vehicle"
              path="/add-vehicle"
            />
            <div className="banner-heading">
              <div style={{ width: "100%" }}>
                <Grid
                  gridMargins={0}
                  overrides={{
                    Grid: { style: { justifyContent: "flex-end" } },
                  }}
                >
                  <Cell span={[4, 4, 3]}>
                    <Select
                      value={company}
                      options={companiesList}
                      backspaceRemoves={false}
                      placeholder="Select Company"
                      onChange={({ value }) => onChangeCompany(value)}
                      overrides={{
                        Dropdown: { style: { maxHeight: "300px" } },
                        SelectArrow: { style: { padding: 0, display: "none" } },
                      }}
                    />
                  </Cell>
                </Grid>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              <div style={{ color: "gray" }}>{count || 0} Vehicle</div>
              <CustomPagination
                totalItems={count}
                onChange={setPage}
                currentPage={page}
                itemsPerPage={20}
              />
            </div>
            <div className="form-box">
              <StyledRoot>
                {vehicle?.[0] === null ? (
                  <div>No results found</div>
                ) : (
                  <StyledTable>
                    <StyledTableHead>
                      <StyledTableHeadRow>
                        <StyledTableHeadCell>Plate</StyledTableHeadCell>
                        <StyledTableHeadCell>Company</StyledTableHeadCell>
                        <StyledTableHeadCell>Documents</StyledTableHeadCell>

                        <StyledTableHeadCell
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Action
                        </StyledTableHeadCell>
                      </StyledTableHeadRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {vehicle && vehicle.length !== 0 && !searched
                        ? vehicle.map((row, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {row.plate}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {row.company_id?.name}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div className="hello">
                                  {row.documents.map((data) => {
                                    return (
                                      <Link
                                        target="_blank"
                                        to={{
                                          pathname: data,
                                        }}
                                        className="tooltip"
                                      >
                                        <img
                                          className=""
                                          width={35}
                                          height={35}
                                          src={
                                            checkSubstringsCaseInsensitive(
                                              data,
                                              ["pdf"]
                                            )
                                              ? Pdf
                                              : checkSubstringsCaseInsensitive(
                                                  data,
                                                  ["png", "jpg", "jpeg"]
                                                )
                                              ? Img
                                              : Document
                                          }
                                          alt=""
                                        />

                                        {data && (
                                          <div className="tooltiptext">
                                            show
                                          </div>
                                        )}
                                      </Link>
                                    );
                                  })}
                                </div>
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setVehicleDel(true);
                                    setplate(row._id);
                                  }}
                                  size="compact"
                                  overrides={{
                                    BaseButton: {
                                      style: ({ $theme }) => ({
                                        backgroundColor: "rgb(183, 33, 54);",
                                      }),
                                    },
                                  }}
                                >
                                  DELETE
                                </Button>

                                <Button
                                  onClick={() => {
                                    vehicleService.setIndex(index);
                                    history.push(`/update-vehicle/${row._id}`);
                                  }}
                                  size="compact"
                                  overrides={{
                                    BaseButton: {
                                      style: ({ $theme }) => ({
                                        marginLeft: "7px",
                                      }),
                                    },
                                  }}
                                >
                                  UPDATE
                                </Button>
                              </StyledTableBodyCell>
                            </StyledTableBodyRow>
                          ))
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                            </StyledTableBodyRow>
                          ))}
                    </StyledTableBody>
                  </StyledTable>
                )}
              </StyledRoot>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                paddingBottom: "10px",
              }}
            >
              <CustomPagination
                totalItems={count}
                onChange={setPage}
                currentPage={page}
                itemsPerPage={20}
              />
            </div>
          </Cell>
        </Grid>

        <Modal
          isOpen={vehicleDel}
          unstable_ModalBackdropScroll={true}
          onClose={() => setVehicleDel(false)}
        >
          {isLoading && <CircleLoader />}
          <FocusOnce>
            <ModalHeader>Delete Vehicle</ModalHeader>
          </FocusOnce>
          <ModalBody>Do you really want to delete this Vehicle?</ModalBody>
          <ModalFooter>
            <ModalButton
              type="button"
              kind="tertiary"
              onClick={() => setVehicleDel(false)}
            >
              No
            </ModalButton>
            <ModalButton onClick={handleDelete}>Yes</ModalButton>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
