import { ADD_REMARKS, GET_CAREERS } from "./careerType";

const initialState = {
  careers: [],
};

const careerReducer = (state = initialState, action) => {
  let index, allCareers;
  switch (action.type) {
    case GET_CAREERS:
      return {
        ...state,
        careers:
          action.payload.data.careers.length > 0
            ? action.payload.data.careers
            : [null],
      };
    case ADD_REMARKS:
      allCareers = [...state.careers];
      index = allCareers.findIndex(
        (row) => row._id === action.payload.data.career._id
      );
      allCareers[index] = action.payload.data.career;
      return {
        ...state,
        careers: allCareers,
      };
    default:
      return state;
  }
};
export default careerReducer;
