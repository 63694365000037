import { change, Field, reduxForm } from "redux-form";
import { Cell, Grid } from "baseui/layout-grid";
import Button from "../../../../../_common/Button";
import { required, requiredSelect } from "../../../../../_common/validate";
import { FormControl } from "baseui/form-control";
import FileUpload from "../../../../../_common/FileUpload";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../_common/Input";
import RenderSelectField from "../../../../../_common/SelectRedux";
import AllowRender from "../../../../../_common/AllowRender";
import SelectDriver from "../../SelectDriver";
import { getDrivers } from "../../../../../redux/driver/driverAction";

const AddCashPaidForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();


 
 


 


    useEffect(() => {
      dispatch(getDrivers());
    }, [dispatch]);


  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <SelectDriver />
        </Cell>
      </Grid>
      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Date"}
            overrides={{
              Label: { style: { marginBottom: "4px" } },
              ControlContainer: { style: { position: "relative" } },
            }}>
            <Field
              type="date"
              component={Input}
              label="Date"
              validate={[required]}
              placeholder="dd-mm-yyyy"
              name="date"
            />
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Enter Price"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}>
            <Field
              name="price"
              validate={[required]}
              component={Input}
              autoComplete="false"
            />
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Given By"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}>
            <Field
              name="givenBy"
              validate={[required]}
              component={Input}
              autoComplete="false"
            />
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
            <Field
            name="paymentMode"
            label="Mode of Payment"
            size="compact"
            placeholder="Select mode of payment"
            validate={[requiredSelect]}
            component={RenderSelectField}
            options={[
              { label: "Cash", id: "cash" },
              { label: "Bank Transfer", id: "bank" },
            ]}
          />
        </Cell>
      </Grid>


      <Grid gridMargins={0} gridGutters={8}>
        <Cell skip={[0, 0, 0]} span={[4, 4, 4]}>
          <Button style={{ width: "100%" }}>Submit</Button>
        </Cell>
      </Grid>
    </form>
  );
};

export default reduxForm({ form: "AddCashPaidForm" })(AddCashPaidForm);
