import TableLoader from "../../../_common/TableLoader";
import CircleLoader from "../../../_common/CircleLoader";
import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import {
  Modal,
  FocusOnce,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalButton,
} from "baseui/modal";
import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import BannerHeading from "../../../_common/BannerHeading";
import { useHistory } from "react-router-dom";
import { Select } from "baseui/select";
import { Button } from "baseui/button";
import "../Vehicle/vehicle.css";
import moment from "moment";
import { DatePicker } from "baseui/datepicker";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCashPaid,
  getCashPaid,
  getTotalCashPaid,
} from "../../../redux/cashPaid/cashPaidAction";
import cashPaidService from "../../../_services/cashPaid.service";
import { getDrivers } from "../../../redux/driver/driverAction";
import TotalCashPaid from "./TotalCashPaid";

export default function CashPaid() {
  const dispatch = useDispatch();

  const [plate, setPlate] = useState();
  const [searched, setSearched] = useState(false);
  const [driversList, setDriverList] = useState([]);
  const [date, setDate] = useState([]);
  const [cashDel, setCashDel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [driver, setDriver] = useState([]);
  const [disable, setDisable] = useState();

  const cashPaid = useSelector((state) => state.cashPaid.cashPaid);
  const drivers = useSelector((state) => state.drivers.drivers);

  useEffect(() => {
    (async () => {
      let data = {};

      if (date.length === 2) {
        const convertDate = (date) => {
          let d = new Date(date),
            dd = d.getDate(),
            mm = d.getMonth() + 1,
            yy = d.getFullYear();

          return `${yy}-${mm > 9 ? mm : "0" + mm}-${dd > 9 ? dd : "0" + dd}`;
        };

        data.start_date = convertDate(date[0]);
        data.end_date = convertDate(date[1]);
      }

      if (driver?.[0]?.id) data._id = driver[0].id;

      await dispatch(getCashPaid(data));

      setSearched(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, driver, searched]);

  useEffect(() => {
    let total = {};

    if (date.length === 2) {
      const convertDate = (date) => {
        let d = new Date(date),
          dd = d.getDate(),
          mm = d.getMonth() + 1,
          yy = d.getFullYear();

        return `${yy}-${mm > 9 ? mm : "0" + mm}-${dd > 9 ? dd : "0" + dd}`;
      };

      total.start_date = convertDate(date[0]);
      total.end_date = convertDate(date[1]);
    }

    if (driver?.[0]?.id) total.driverId = driver[0].id;

    if (total.driverId || total.start_date || total.end_date) {
      dispatch(getTotalCashPaid(total));
    }
    setDisable(total);
    setSearched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, driver, searched]);

  useEffect(() => {
    dispatch(getDrivers());
  }, [dispatch]);

  useEffect(() => {
    if (drivers?.[0] === null) return;

    let newArr = [];
    drivers.forEach(({ _id, first_name, last_name }) =>
      newArr.push({ id: _id, label: first_name + " " + last_name })
    );

    setDriverList(newArr);
  }, [drivers]);

  const handleDelete = async () => {
    setIsLoading(true);
    await dispatch(deleteCashPaid(plate));

    setIsLoading(false);
    setCashDel("");
  };

  const onChangeCompany = (value) => {
    setSearched(true);
    setDriver(value);
  };

  const onChangeDate = (value) => {
    setDate(Array.isArray(value) ? value : [value]);

    if (value.length === 1 || (value.length === 0 && date.length === 0)) return;
    if (value.length === 2 || driver.length === 1) setSearched(true);
    else if (
      value.length !== 1 &&
      value.length !== 2 &&
      value.length !== date.length
    )
      setSearched(true);
  };

  const history = useHistory();

  return (
    <>
      <div className="dashboard-main">
        <Grid align={ALIGNMENT.center}>
          <Cell span={[4, 8, 12]}>
            <BannerHeading
              title="Cash Paid"
              text="Add Cash Paid"
              path="/add-cashPaid"
            />
            <div className="banner-heading">
              <div style={{ width: "100%" }}>
                <Grid
                  gridMargins={0}
                  overrides={{
                    Grid: { style: { justifyContent: "flex-end" } },
                  }}
                >
                  <Cell span={[4, 4, 3]}>
                    <Select
                      value={driver}
                      options={driversList}
                      backspaceRemoves={false}
                      size="default"
                      placeholder="Select Driver"
                      onChange={({ value }) => onChangeCompany(value)}
                      overrides={{
                        Dropdown: { style: { maxHeight: "300px" } },
                        SelectArrow: {
                          style: { padding: 0, display: "none" },
                        },
                      }}
                    />
                  </Cell>

                  <Cell span={[4, 4, 3]}>
                    <DatePicker
                      range
                      clearable
                      value={date}
                      size="default"
                      maxDate={new Date()}
                      formatString="dd.MM.yyyy"
                      placeholder="DD.MM.YYYY - DD.MM.YYYY"
                      onChange={({ date }) => onChangeDate(date)}
                    />
                  </Cell>
                </Grid>
              </div>
            </div>

            {(disable?.driverId || disable?.start_date) === undefined ||
            null ||
            "" ? (
              <></>
            ) : (
              <TotalCashPaid />
            )}
            <div className="form-box">
              <StyledRoot>
                {cashPaid?.[0] === null ? (
                  <div>No results found</div>
                ) : (
                  <StyledTable>
                    <StyledTableHead>
                      <StyledTableHeadRow>
                        <StyledTableHeadCell>ID</StyledTableHeadCell>
                        <StyledTableHeadCell>Date</StyledTableHeadCell>

                        <StyledTableHeadCell>Driver Name</StyledTableHeadCell>
                        <StyledTableHeadCell>Given By</StyledTableHeadCell>
                        <StyledTableHeadCell>Payment Mode</StyledTableHeadCell>
                        <StyledTableHeadCell>Amount</StyledTableHeadCell>
                        <StyledTableHeadCell
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Action
                        </StyledTableHeadCell>
                      </StyledTableHeadRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {cashPaid && cashPaid.length !== 0 && !searched
                        ? cashPaid.map((data, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {data.id}
                              </StyledTableBodyCell>

                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {moment(data.date).format("DD.MM.YYYY")}
                              </StyledTableBodyCell>

                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {
                                  <div className="hello">
                                    {data.driverId?.first_name +
                                      " " +
                                      data.driverId?.last_name}
                                  </div>
                                }
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {data?.givenBy}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {data?.paymentMode === "cash"
                                  ? "Cash"
                                  : data?.paymentMode === "bank"
                                  ? "Bank Transfer"
                                  : ""}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {
                                  <div className="hello">
                                    €{parseFloat(data.price)?.toFixed(2)}
                                  </div>
                                }
                              </StyledTableBodyCell>

                              <StyledTableBodyCell
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    setCashDel(true);
                                    setPlate(data._id);
                                  }}
                                  size="compact"
                                  overrides={{
                                    BaseButton: {
                                      style: ({ $theme }) => ({
                                        backgroundColor: "rgb(183, 33, 54);",
                                      }),
                                    },
                                  }}
                                >
                                  DELETE
                                </Button>

                                <Button
                                  onClick={() => {
                                    cashPaidService.setIndex(index);
                                    history.push(
                                      `/update-cashPaid/${data._id}`
                                    );
                                  }}
                                  size="compact"
                                  overrides={{
                                    BaseButton: {
                                      style: ({ $theme }) => ({
                                        marginLeft: "7px",
                                      }),
                                    },
                                  }}
                                >
                                  UPDATE
                                </Button>
                              </StyledTableBodyCell>
                            </StyledTableBodyRow>
                          ))
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                            </StyledTableBodyRow>
                          ))}
                    </StyledTableBody>
                  </StyledTable>
                )}
              </StyledRoot>
            </div>
          </Cell>
        </Grid>

        <Modal
          isOpen={cashDel}
          unstable_ModalBackdropScroll={true}
          onClose={() => setCashDel(false)}
        >
          {isLoading && <CircleLoader />}
          <FocusOnce>
            <ModalHeader>Delete Cash Paid</ModalHeader>
          </FocusOnce>
          <ModalBody>Do you really want to delete this Cash Paid?</ModalBody>
          <ModalFooter>
            <ModalButton
              type="button"
              kind="tertiary"
              onClick={() => setCashDel(false)}
            >
              No
            </ModalButton>
            <ModalButton onClick={handleDelete}>Yes</ModalButton>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
