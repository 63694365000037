import {
  ADD_PARTNER,
  DELETE_PARTNER,
  GET_PARTNER,
  UPDATE_PARTNER,
} from "./partnerType";

const initialState = {
  partners: [],
  private_partner: null,
};

const partnerReducer = (state = initialState, action) => {
  let index, allPartners;
  switch (action.type) {
    case ADD_PARTNER:
      allPartners = [...state.partners];
      if (allPartners?.[0] === null) allPartners = [];
      allPartners.unshift(action.payload.data.partner);

      return {
        ...state,
        partners: allPartners,
      };
    case GET_PARTNER:
      return {
        ...state,
        private_partner: action.payload.data.private_partner,
        partners:
          action.payload.data.partners.length > 0
            ? action.payload.data.partners
            : [null],
      };
    case UPDATE_PARTNER:
      if (action.payload.data.partner._id === state?.private_partner?._id)
        return {
          ...state,
          private_partner: action.payload.data.partner,
        };

      allPartners = [...state.partners];
      index = allPartners.findIndex(
        (row) => row._id === action.payload.data.partner._id
      );
      allPartners[index] = action.payload.data.partner;
      return {
        ...state,
        partners: allPartners,
      };
    case DELETE_PARTNER:
      return {
        ...state,
        partners: state.partners.filter((item) => item._id !== action.payload),
      };
    default:
      return state;
  }
};

export default partnerReducer;
