import {
	CLEAR_EARNINGS,
	GET_DRIVER_EARNINGS,
	GET_EARNINGS,
	GET_PRIVATE_EARNINGS,
	IS_DRAFT,
	UPDATE_EARNING,
} from "./earningsType";

const initialState = {
	earning: [],
	private: [],
	driver_earnings: [],
};


const earningsReducer = (state = initialState, action) => {
	let index, allEarnings;
	switch (action.type) {
		case GET_EARNINGS:
			return {
				...state,
				earning:
					action.payload.data.earnings_array.length > 0
						? action.payload.data.earnings_array
						: [null],
			};
		case GET_PRIVATE_EARNINGS:
			return {
				...state,
				private: action.payload.length > 0 ? action.payload : [null],
			};
		case GET_DRIVER_EARNINGS:
			return {
				...state,
				driver_earnings: action.payload,
			};
		case UPDATE_EARNING:
			allEarnings = [...state.earning];
			index = allEarnings.findIndex(
				(row) => row._id === action.payload.data
			);
			allEarnings[index] = action.payload.earning;
			return {
				...state,
				earning: allEarnings,
			};
		case CLEAR_EARNINGS:
			return {
				...state,
				earning: [],
			};
		case IS_DRAFT:
			return {
				...state,
				is_draft: action.payload,
			};
		default:
			return state;
	}
};
export default earningsReducer;
