import ToasterService from "../_services/toasterService";
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";

export default function Toaster() {
  let toastKey;

  const successToaster = (toaster_content) => {
    toastKey = toaster.positive(<>{toaster_content}</>, {
      overrides: {
        InnerContainer: {
          style: { width: "100%" },
        },
      },
    });
  };

  const errorToaster = (toaster_content) => {
    toastKey = toaster.negative(<>{toaster_content}</>, {
      overrides: {
        InnerContainer: {
          style: { width: "100%" },
        },
      },
    });
  };

  ToasterService.subscribeToToaster(successToaster, errorToaster);

  return (
    <ToasterContainer
      autoHideDuration={3000}
      overrides={{
        Root: {
          style: () => ({
            zIndex: "999",
          }),
        },
      }}
      placement={PLACEMENT.bottomRight}
    >
      {false && toastKey}
    </ToasterContainer>
  );
}
