import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function BlockRender({ roles, children }) {
	const [block, setBlock] = useState(false);
	const role = useSelector((state) => state.auth?.user?.role);

	useEffect(() => {
		for (let i = 0; i < roles.length; i++) {
			const el = roles[i];

			if (role === el) {
				setBlock(true);
				break;
			}
		}
	}, [role, roles]);

	return <>{!block && children}</>;
}
