import { Route, Redirect } from "react-router-dom";
import authService from "../_services/auth.service";

export default function AdminPrivateRoute({
	component: Component,
	children,
	render,
	...rest
}) {
	const user = authService.currentUser();

	return (
		<Route
			{...rest}
			render={({ location }) =>
				user &&
				(user.role === "admin" ||
					user.role === "manager" ||
					(user.role === "accountant" && location.pathname === "/invoices")) ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}
