import { change } from "redux-form";
import { useHistory, withRouter } from "react-router";

import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import cashPaidService from "../../../../../_services/cashPaid.service";
import moment from "moment";

let UpdateCashPaidForm = ({ id }) => {
  const isSet = useRef(false);
  const dispatch = useDispatch();
  const cashPaid = useSelector((state) => state.cashPaid.cashPaid);
  const history = useHistory();

  useEffect(() => {
    if (isSet.current) return;

    const index = cashPaidService.getIndex();
    if (isNaN(index)) return history.push("/cash-paid");

    const data = cashPaid?.[index];
    if (!data) return history.push("/cash-paid");


    
    const { date, price, driverId, givenBy, paymentMode } = data;
    
    let date2 = moment(date).format("YYYY-MM-DD");


     dispatch(change("AddCashPaidForm", "date", date2));
    dispatch(change("AddCashPaidForm", "price", price));
    dispatch(change("AddCashPaidForm", "givenBy", givenBy));
    dispatch(change("AddCashPaidForm", "paymentMode", paymentMode));
    dispatch(change("AddCashPaidForm", "driver", [
        { id: driverId._id, label: driverId.first_name },
      ])
    );

    isSet.current = true;
  }, [cashPaid, history, dispatch]);

  return <></>;
};

export default withRouter(UpdateCashPaidForm);
