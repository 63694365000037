import { change, Field, reduxForm } from "redux-form";
import { Cell, Grid } from "baseui/layout-grid";
import Button from "../../../../../_common/Button";
import { required } from "../../../../../_common/validate";
import { FormControl } from "baseui/form-control";
import FileUpload from "../../../../../_common/FileUpload";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../_common/Input";
import RenderSelectField from "../../../../../_common/SelectRedux";
import AllowRender from "../../../../../_common/AllowRender";
import SelectDriver from "../../SelectDriver";
import { getDrivers } from "../../../../../redux/driver/driverAction";

const AddExpenseForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const role = useSelector((state) => state.auth?.user?.role);
  const label = useSelector(
    (state) => state.form.AddExpenseForm?.values?.fueltype?.[0]?.label
  );

  const [pendingImages, setPendingImages] = useState([]);

  const imageUploadStart = (name) =>
    setPendingImages((prev) => [...prev, name]);
  useEffect(() => {}, [pendingImages]);
  const imageUploadEnd = (image, name) => {
    setPendingImages((prev) => {
      let array = [];
      prev &&
        prev.forEach((imgName) => {
          if (imgName !== name) {
            array.push(imgName);
          }
        });

      return array;
    });

    dispatch(change("AddExpenseForm", name, image));
  };

  useEffect(() => {
    if (role !== "driver") dispatch(getDrivers());
  }, [dispatch, role]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AllowRender roles={["admin"]}>
        <Grid gridMargins={0} gridGutters={16}>
          <Cell span={[4, 8, 4]}>
            <SelectDriver />
          </Cell>
        </Grid>
      </AllowRender>
      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Date"}
            overrides={{
              Label: { style: { marginBottom: "4px" } },
              ControlContainer: { style: { position: "relative" } },
            }}>
            <Field
              type="date"
              component={Input}
              label="Date"
              validate={[required]}
              placeholder="dd-mm-yyyy"
              name="date"
            />
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={16}>
        <Cell span={[4, 8, 4]}>
          <FormControl
            label={() => "Select Expense Type"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}>
            <Field
              size="compact"
              name="fueltype"
              placeholder="Select Expense"
              validate={[required]}
              component={RenderSelectField}
              options={[
                { label: "Fuel", id: "fuel" },
                { label: "Wash", id: "wash" },
                { label: "Water", id: "water" },
                { label: "Parking", id: "parking" },
                { label: "Other", id: "other" },
              ]}
              overrides={{
                Dropdown: { style: { maxHeight: "300px" } },
              }}
            />
          </FormControl>

          <FormControl
            label={() => "Enter Price"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}>
            <Field
              name="price"
              validate={[required]}
              component={Input}
              autoComplete="false"
            />
          </FormControl>

          {label === "Other" ? (
            <FormControl
              label={() => "Explain the Expense"}
              overrides={{ Label: { style: { marginBottom: "4px" } } }}>
              <Field
                name="other"
                validate={[required]}
                component={Input}
                autoComplete="false"
              />
            </FormControl>
          ) : null}

          <FormControl
            label={() => "Receipt picture"}
            overrides={{ Label: { style: { marginBottom: "4px" } } }}>
            <>
              <FileUpload
                form="AddExpenseForm"
                name="reciept"
                imageUploadEnd={imageUploadEnd}
                accept={[".png", ".jpg", ".jpeg"]}
                imageUploadStart={imageUploadStart}
              />
              <Field
                type="hidden"
                component="input"
                name="reciept"
                autoComplete="false"
              />
            </>
          </FormControl>
        </Cell>
      </Grid>

      <Grid gridMargins={0} gridGutters={16}>
        <Cell skip={[0, 0, 0]} span={[4, 4, 4]}>
          {pendingImages.length === 0 && (
            <Button style={{ width: "100%" }}>Submit</Button>
          )}
          {pendingImages.length !== 0 && (
            <Button style={{ width: "100%" }} disabled={true}>
              Uploading Image
            </Button>
          )}
        </Cell>
      </Grid>
    </form>
  );
};

export default reduxForm({ form: "AddExpenseForm" })(AddExpenseForm);
