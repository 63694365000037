import http, { setJWT } from "./http";
let index;

const expenseService = {
  getIndex: () => index,
  setIndex: (id) => (index = id),

  getExpense: (data) => {
    setJWT();
    return http.post("/user/getexpense", data);
  },

  addExpense: (data) => {
    return http.post("/user/addexpense", data);
  },
  updateExpense: (data) => {
    return http.post("/user/updateexpense", data);
  },
  totalExpense: (data) => {
    return http.post("/user/totalExpense", data);
  },
  deleteExpense: (data) => {
    setJWT();
    return http.post("/user/deleteExpense", data);
  },
};

export default expenseService;
