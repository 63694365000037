import {
  ADD_VEHICLE,
  DELETE_VEHICLE,
  GET_VEHICLES,
  UPDATE_VEHICLE,
} from "./vehicleType";

const initialState = {
  vehicles: [],
  page: 1,
  count: 0,
};

const vehicleReducer = (state = initialState, action) => {
  let index, allVehicles;
  switch (action.type) {
    case ADD_VEHICLE:
      allVehicles = [...state.vehicles];
      if (allVehicles?.[0] === null) allVehicles = [];
      allVehicles.unshift(action.payload);

      return {
        ...state,
        vehicles: allVehicles,
      }; 
    case GET_VEHICLES:
      return {
        ...state,
        count:action.payload.count,
        vehicles:
          action.payload.CompanyVehicles.length > 0
            ? action.payload.CompanyVehicles
            : [null],
      };
    case UPDATE_VEHICLE:
      allVehicles = [...state.vehicles];
      index = allVehicles.findIndex((row) => row._id === action.payload.data);
      allVehicles[index] = action.payload.vehicle;

      return {
        ...state,
        vehicles: allVehicles,
      };

    case DELETE_VEHICLE:
      allVehicles = [...state.vehicles];
      allVehicles = allVehicles.filter((item) => item._id !== action.payload);
      allVehicles = allVehicles.length > 0 ? allVehicles : [null];

      return {
        ...state,
        vehicles: allVehicles,
      };
    default:
      return state;
  }
};
export default vehicleReducer;
