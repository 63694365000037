import { useState } from "react";
import { FileUploader } from "baseui/file-uploader";
import adminService from "../_services/admin.service";
import ToasterService from "../_services/toasterService";
import { useDispatch, useSelector } from "react-redux";
import CircleLoader from "./CircleLoader";
import { change } from "redux-form";
import download from "../_assets/download.png";
import pdf from "../_assets/pdf.svg";

export default function MultiFileUpload(props) {
  const [uploading, setUploading] = useState(false);
  const [imgUrl] = useState(null);

  const dispatch = useDispatch();

  let images = useSelector(
    (state) => state.form.AddVehicleForm?.values?.documents
  );

  return (
    <div style={{ position: "relative" }}>
      {uploading && <CircleLoader />}
      {!imgUrl ? (
        <FileUploader
          multiple={true}
          maxSize={6000000}
          accept={props.accept}
          onDrop={(acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
              if (rejectedFiles[0].size > 100000000)
                return ToasterService.showErrorToaster(
                  "Max file size is 10MB!"
                );

              let types = rejectedFiles[0].type.split("/");
              let type = "." + types[types.length - 1];

              if (!props.accept.includes(type))
                return ToasterService.showErrorToaster(
                  `Type ${type} not allowed. Please choose correct format!`
                );
            }

            let formData = new FormData();
            for (const file of acceptedFiles) {
              formData.append("image", file);
            }
            setUploading(true);

            adminService
              .imageUpload(formData)
              .then((response) => {
                setUploading(false);
                let previousImages = images === undefined ? [] : [...images];
                let newImages = response.data.data.url;
                dispatch(
                  change(props.form, props.name, [...previousImages, newImages])
                );
              })
              .catch((err) => {
                setUploading(false);
                ToasterService.showErrorToaster(err.message);
              });
          }}
        />
      ) : null}

      {images?.length > 0 && <div className="uploaded">Uploaded Docements</div>}
      <div className="hello">
        {images?.map((data, i) => {
          return (
            <div key={i} className="tooltip">
              <img
                className="sImg"
                src={data.includes("pdf") || data.includes(".PDF") ? pdf : data}
                alt=""
              />

              <a
                target="_blank"
                rel="noreferrer"
                href={data}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "20px",
                  cursor: "pointer",
                  background: "white",
                  boxShadow: "0 0 5px 1px #cdcdcd",
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontSize: "10px",
                  color: "red",
                  textDecoration: "none",
                }}
              >
                <img
                  src={download}
                  alt="download"
                  style={{ width: "10px", verticalAlign: "middle" }}
                />
              </a>
              <span
                onClick={() => {
                  let previousImages = [...images];
                  previousImages.splice(i, 1);
                  dispatch(
                    change(
                      props.form,
                      props.name,

                      previousImages
                    )
                  );
                }}
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  cursor: "pointer",
                  background: "white",
                  boxShadow: "0 0 5px 1px #cdcdcd",
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontSize: "10px",
                  color: "red",
                }}
              >
                X
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
