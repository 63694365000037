import ToasterService from "../../_services/toasterService";
import { getOpenInvoices } from "../../_services/invoice.service";
import {
  ADD_INVOICE,
  GET_INVOICE,
  EMAIL_NOTIFY,
  CHANGE_STATUS,
  UPDATE_INVOICE,
  INCREMENT_PAGE,
  CLEAR_INVOICES,
  ERROR_INVOICES,
  GET_INVOICE_ADMIN,
  REMOVE_INVOICE,
} from "./invoiceType";
import { config } from "../../config";

const initialState = {
  invoices: [],
  page: 1,
  count: 0,
  more: true,
  reset: 0,
  draft: false,
};

const invoiceReducer = (state = initialState, action) => {
  let index, allInvoices;
  switch (action.type) {
    case ADD_INVOICE:
      allInvoices = [...state.invoices];
      if (allInvoices?.[0] === null) allInvoices = [];
      allInvoices.unshift(action.payload.data.invoice);

      return {
        ...state,
        invoices: allInvoices,
      };
    case GET_INVOICE:
      return {
        ...state,
        count: action.payload.data.length,
        invoices: action.payload.data.length > 0 ? action.payload.data : [null],
        draft: action.payload.draft || false,
      };
    case GET_INVOICE_ADMIN:
      const { count, invoices, draft } = action.payload;

      if (state.page === 1) allInvoices = [...invoices];
      else allInvoices = [...state.invoices, ...invoices];

      return {
        ...state,
        count,
        more: invoices.length == config.EXPENSE_PAGE_SIZE,
        invoices: allInvoices.length > 0 ? allInvoices : [null],
        draft,
      };

    case UPDATE_INVOICE:
      allInvoices = [...state.invoices];
      index = allInvoices.findIndex(
        (row) => row.invoice_id === action.payload.data.invoice.invoice_id
      );
      allInvoices[index] = action.payload.data.invoice;
      return {
        ...state,
        invoices: allInvoices,
      };
    case REMOVE_INVOICE:
      return {
        ...state,
        invoices: state.invoices?.filter((e) => e._id !== action.payload._id),
      };
    case CHANGE_STATUS:
      let newCount = state.count;
      let open = getOpenInvoices();
      const { data } = action.payload;
      allInvoices = [...state.invoices];

      index = allInvoices.findIndex(
        (row) => row.invoice_id === data.invoice_id
      );

      if ((open && data.verified) || (!open && !data.verified)) {
        newCount--;
        allInvoices.splice(index, 1);
        if (allInvoices.length === 0) allInvoices = [null];
        if (open) ToasterService.showSuccessToaster("Invoice Closed");
        else ToasterService.showSuccessToaster("Invoice Opened");
      } else {
        allInvoices[index].status = data.status;
        allInvoices[index].verified = data.verified;
        allInvoices[index].payed_by = data.payed_by;
      }

      return {
        ...state,
        count: newCount,
        invoices: allInvoices,
      };

    case EMAIL_NOTIFY:
      allInvoices = [...state.invoices];
      index = allInvoices.findIndex(
        (row) => row._id === action.payload.data.data._id
      );
      allInvoices[index].email_count = action.payload.data.data.email_count;

      return {
        ...state,
        invoices: allInvoices,
      };

    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case CLEAR_INVOICES:
      return {
        ...state,
        page: 1,
        count: 0,
        more: true,
        invoices: [],
        reset: state.reset + 1,
      };
    case ERROR_INVOICES:
      return {
        ...state,
        page: 1,
        count: 0,
        more: false,
        invoices: [null],
      };
    default:
      return state;
  }
};

export default invoiceReducer;
