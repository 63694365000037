import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../../../_common/TableLoader";
import CircleLoader from "../../../_common/CircleLoader";
import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import "./Expense.css";
import {
  Modal,
  FocusOnce,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalButton,
} from "baseui/modal";
import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import BannerHeading from "../../../_common/BannerHeading";
import { Link, useHistory } from "react-router-dom";
import { Select } from "baseui/select";
import { getDrivers } from "../../../redux/driver/driverAction";
import {
  clearExpense,
  deleteExpense,
  getExpense,
  getTotalExpense,
  incrementPage,
} from "../../../redux/expense/expenseAction";
import { Button } from "baseui/button";
import expenseService from "../../../_services/expense.service";
import "../Vehicle/vehicle.css";
import moment from "moment";
import AllowRender from "../../../_common/AllowRender";
import { useRef } from "react";
import { DatePicker } from "baseui/datepicker";
import MonthlyExpense from "./MonthlyExpense";
import CustomPagination from "../../../_common/CustomPagination";
import ReceiptIcon from "../../../_assets/receipt.svg";

export default function Expense() {
  const dispatch = useDispatch();
  const history = useHistory();

  const first = useRef(true);
  const loader = useRef(null);
  const moreRef = useRef(true);
  const fetching = useRef(true);
  const unmount = useRef(false);
  const moreFirst = useRef(true);
  const observerRef = useRef(null);
  const moreFirstTime = useRef(true);

  const [driver, setDriver] = useState([]);
  const [searched, setSearched] = useState(false);
  const [driversList, setDriverList] = useState([]);
  const [date, setDate] = useState([]);
  const [plate, setplate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [expenseDel, setExpenseDel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState();
  const [page, setPage] = useState(1);

  const drivers = useSelector((state) => state.drivers.drivers);

  const { expense, reset, more, count } = useSelector((state) => state.expense);
  const user = useSelector((state) => state.auth?.user);
  useEffect(() => {
    if (!moreFirstTime.current) return;

    moreFirst.current = more;
    moreFirstTime.current = false;
  }, [more, dispatch]);

  useEffect(() => {
    return () => {
      unmount.current = true;
      dispatch(clearExpense());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!searched) return;

    first.current = true;
    moreRef.current = true;
    fetching.current = false;
    observerRef.current = null;

    dispatch(clearExpense());
    setSearched(false);
  }, [dispatch, searched, setSearched]);

  useEffect(() => {
    if (!searched && observerRef.current) return;

    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !fetching.current) dispatch(incrementPage());
    };

    const option = { root: null, rootMargin: "0px", threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observerRef.current = observer;

    if (loader.current) observer.observe(loader.current);
  }, [dispatch, searched]);

  useEffect(() => {
    if (!more && observerRef.current) {
      moreRef.current = false;
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  }, [more]);

  const onChangeDate = (value) => {
    setDate(Array.isArray(value) ? value : [value]);

    if (value.length === 1 || (value.length === 0 && date.length === 0)) return;
    if (value.length === 2 || driver.length === 1) setSearched(true);
    else if (
      driver.length === 0 &&
      value.length !== 1 &&
      value.length !== 2 &&
      value.length !== date.length
    )
      setSearched(true);
  };

  useEffect(() => {
    let data = {};

    if (date.length === 2) {
      const convertDate = (date) => {
        let d = new Date(date),
          dd = d.getDate(),
          mm = d.getMonth() + 1,
          yy = d.getFullYear();

        return `${yy}-${mm > 9 ? mm : "0" + mm}-${dd > 9 ? dd : "0" + dd}`;
      };

      data.start_date = convertDate(date[0]);
      data.end_date = convertDate(date[1]);
    }
    if (unmount.current || page < 1) return;

    setLoading(true);
    fetching.current = true;

    data.page = page;

    if (driver?.[0]?.id) data._id = driver[0].id;
    else if (user && user.role === "driver") data._id = user._id;

    dispatch(getExpense(data)).then(() => {
      if (!unmount.current) setLoading(false);
      fetching.current = false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reset, dispatch, driver, searched]);

  useEffect(() => {
    if (moreFirst.current) return;

    first.current = true;
    moreRef.current = true;
    fetching.current = false;
    observerRef.current = null;

    dispatch(clearExpense());
  }, [dispatch]);

  useEffect(() => {
    if (drivers?.[0] === null) return;

    let newArr = [];
    drivers.forEach(({ _id, first_name, last_name }) =>
      newArr.push({ id: _id, label: first_name + " " + last_name })
    );

    setDriverList(newArr);
  }, [drivers]);

  const handleDelete = async () => {
    setIsLoading(true);
    await dispatch(deleteExpense(plate));

    setExpenseDel("");
    setIsLoading(false);
  };
  const onChangeCompany = (value) => {
    setSearched(true);
    setDriver(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => user.role === "admin" && dispatch(getDrivers()), [dispatch]);

  useEffect(() => {
    let total = { page };

    if (date.length === 2) {
      const convertDate = (date) => {
        let d = new Date(date),
          dd = d.getDate(),
          mm = d.getMonth() + 1,
          yy = d.getFullYear();

        return `${yy}-${mm > 9 ? mm : "0" + mm}-${dd > 9 ? dd : "0" + dd}`;
      };

      total.start_date = convertDate(date[0]);
      total.end_date = convertDate(date[1]);
    }

    if (driver?.[0]?.id) total.driverId = driver[0].id;

    if (total.driverId || total.start_date || total.end_date) {
      dispatch(getTotalExpense(total));
    }
    setDisable(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user, driver, searched, page]);

  return (
    <>
      <div className="dashboard-main">
        <Grid align={ALIGNMENT.center}>
          <Cell span={[4, 8, 12]}>
            <BannerHeading
              title="Expense"
              text="Add Expense"
              path="/add-expense"
            />
            <AllowRender roles={["admin"]}>
              <div className="banner-heading">
                <div style={{ width: "100%" }}>
                  <Grid
                    gridMargins={0}
                    overrides={{
                      Grid: { style: { justifyContent: "flex-end" } },
                    }}
                  >
                    <Cell span={[4, 4, 3]}>
                      <Select
                        value={driver}
                        options={driversList}
                        backspaceRemoves={false}
                        size="default"
                        placeholder="Select Driver"
                        onChange={({ value }) => onChangeCompany(value)}
                        overrides={{
                          Dropdown: { style: { maxHeight: "300px" } },
                          SelectArrow: {
                            style: { padding: 0, display: "none" },
                          },
                        }}
                      />
                    </Cell>

                    <Cell span={[4, 4, 3]}>
                      <DatePicker
                        range
                        clearable
                        value={date}
                        size="default"
                        maxDate={new Date()}
                        formatString="dd.MM.yyyy"
                        placeholder="DD.MM.YYYY - DD.MM.YYYY"
                        onChange={({ date }) => onChangeDate(date)}
                      />
                    </Cell>
                  </Grid>
                </div>
              </div>
            </AllowRender>

            {(disable?.driverId || disable?.start_date) === undefined ||
            null ||
            "" ? (
              <></>
            ) : (
              <AllowRender roles={["admin"]}>
                <MonthlyExpense />
              </AllowRender>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "10px",
              }}
            >
              <div style={{ color: "gray" }}>{count || 0} Expense</div>{" "}
              <CustomPagination
                totalItems={count}
                onChange={setPage}
                currentPage={page}
              />
            </div>
            <div className="form-box">
              <StyledRoot>
                {expense?.[0] === null ? (
                  <div>No results found</div>
                ) : (
                  <StyledTable>
                    <StyledTableHead>
                      <StyledTableHeadRow>
                        <StyledTableHeadCell>Added On</StyledTableHeadCell>
                        <StyledTableHeadCell>Expense Date</StyledTableHeadCell>
                        <AllowRender roles={["admin"]}>
                          <StyledTableHeadCell>Added By</StyledTableHeadCell>
                        </AllowRender>

                        <StyledTableHeadCell>Expense Type</StyledTableHeadCell>
                        <StyledTableHeadCell>Price</StyledTableHeadCell>
                        <StyledTableHeadCell>Receipt</StyledTableHeadCell>
                        <AllowRender roles={["admin"]}>
                          <StyledTableHeadCell
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Action
                          </StyledTableHeadCell>
                        </AllowRender>
                      </StyledTableHeadRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {expense && expense.length !== 0 && !searched && !loading
                        ? expense.map((data, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {moment(data.createdAt).format("DD.MM.YYYY")}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {moment(data.date).format("DD.MM.YYYY")}
                              </StyledTableBodyCell>
                              <AllowRender roles={["admin"]}>
                                <StyledTableBodyCell
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {
                                    <div className="hello">
                                      {`${data.driverId?.first_name} ${data.driverId?.last_name}`}
                                    </div>
                                  }
                                </StyledTableBodyCell>
                              </AllowRender>

                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {<div className="hello">{data.name}</div>}
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {
                                  <div className="hello">
                                    €{parseFloat(data.price)?.toFixed(2)}
                                  </div>
                                }
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {
                                  <div className="hello">
                                    <Link
                                      target="_blank"
                                      to={{
                                        pathname: data.reciept,
                                      }}
                                      className="tooltip"
                                    >
                                      <img
                                        className=""
                                        width={35}
                                        height={35}
                                        src={ReceiptIcon}
                                        alt=""
                                      />

                                      {data.reciept && (
                                        <div className="tooltiptext">show</div>
                                      )}
                                    </Link>
                                  </div>
                                }
                              </StyledTableBodyCell>
                              <AllowRender roles={["admin"]}>
                                <StyledTableBodyCell
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      setExpenseDel(true);
                                      setplate(data._id);
                                    }}
                                    size="compact"
                                    overrides={{
                                      BaseButton: {
                                        style: ({ $theme }) => ({
                                          backgroundColor: "rgb(183, 33, 54);",
                                        }),
                                      },
                                    }}
                                  >
                                    DELETE
                                  </Button>

                                  <Button
                                    onClick={() => {
                                      expenseService.setIndex(index);
                                      history.push(
                                        `/update-expense/${data._id}`
                                      );
                                    }}
                                    size="compact"
                                    overrides={{
                                      BaseButton: {
                                        style: ({ $theme }) => ({
                                          marginLeft: "7px",
                                        }),
                                      },
                                    }}
                                  >
                                    UPDATE
                                  </Button>
                                </StyledTableBodyCell>
                              </AllowRender>
                            </StyledTableBodyRow>
                          ))
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, index) => (
                            <StyledTableBodyRow key={index}>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <StyledTableBodyCell
                                style={{ verticalAlign: "middle" }}
                              >
                                <TableLoader rows={1} />
                              </StyledTableBodyCell>
                              <AllowRender roles={["admin"]}>
                                <StyledTableBodyCell
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <TableLoader rows={1} />
                                </StyledTableBodyCell>
                                <StyledTableBodyCell
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <TableLoader rows={1} />
                                </StyledTableBodyCell>
                              </AllowRender>
                            </StyledTableBodyRow>
                          ))}
                    </StyledTableBody>
                  </StyledTable>
                )}
              </StyledRoot>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                paddingTop: "10px",
              }}
            >
              <CustomPagination
                totalItems={count}
                onChange={setPage}
                currentPage={page}
              />
            </div>
          </Cell>
        </Grid>

        <Modal
          isOpen={expenseDel}
          unstable_ModalBackdropScroll={true}
          onClose={() => setExpenseDel(false)}
        >
          {isLoading && <CircleLoader />}
          <FocusOnce>
            <ModalHeader>Delete Expense</ModalHeader>
          </FocusOnce>
          <ModalBody>Do you really want to delete this Expense?</ModalBody>
          <ModalFooter>
            <ModalButton
              type="button"
              kind="tertiary"
              onClick={() => setExpenseDel(false)}
            >
              No
            </ModalButton>
            <ModalButton onClick={handleDelete}>Yes</ModalButton>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
