import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import { useSelector } from "react-redux";
import TableLoader from "../../../_common/TableLoader";





const TotalCashPaid = () => {

  const {cash} = useSelector((state) => state.cashPaid.totalCashPaid);
  return (
    <div className="form-box">
      <h1>Total Cash Paid</h1>
      <StyledRoot>
        {cash?.length === 0 ? (
          <div>No results found</div>
        ) : (
          <StyledTable>
            <StyledTableHead>
              <StyledTableHeadRow>
                <StyledTableHeadCell>Driver Name</StyledTableHeadCell>
                <StyledTableHeadCell>Price</StyledTableHeadCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
                {
                  cash?.map((data, index) => (
                    <StyledTableBodyRow key={index}>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                        }}>
                        {<div className="hello">{data.name}</div>}
                      </StyledTableBodyCell>

                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                        }}>
                        {
                          <div className="hello">
                            €{parseFloat(data.total)?.toFixed(2)}
                          </div>
                        }
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  ))
                }
            </StyledTableBody>
          </StyledTable>
        )}
      </StyledRoot>
    </div>
  );
};

export default TotalCashPaid;
