import {
  ADD_CASH_PAID,
  GET_CASH_PAID,
  UPDATE_CASH_PAID,
  DELETE_CASH_PAID,
  TOTAL_CASH_PAID,
} from "./cashPaidType";

const initialState = {
  cashPaid: [],
  totalCashPaid: [],
};

const cashPaidReducer = (state = initialState, action) => {
  let index, allCashPaid;
  switch (action.type) {
    case ADD_CASH_PAID:
      allCashPaid = [...state.cashPaid];
      if (allCashPaid?.[0] === null) allCashPaid = [];
      allCashPaid.unshift(action.payload);

      return {
        ...state,
        cashPaid: allCashPaid,
      };
    case GET_CASH_PAID:
      return {
        ...state,
        cashPaid: action.payload.cash.length > 0 ? action.payload.cash : [null],
      };
    case UPDATE_CASH_PAID:
      allCashPaid = [...state.cashPaid];
      index = allCashPaid.findIndex((row) => row._id === action.payload.data);
      allCashPaid[index] = action.payload.cashPaid;

      return {
        ...state,
        cashPaid: allCashPaid,
      };
    case DELETE_CASH_PAID:
      allCashPaid = [...state.cashPaid];
      allCashPaid = allCashPaid.filter((item) => item._id !== action.payload);
      allCashPaid = allCashPaid.length > 0 ? allCashPaid : [null];

      return {
        ...state,
        cashPaid: allCashPaid,
      };
    case TOTAL_CASH_PAID:
      return {
        ...state,
        totalCashPaid: action.payload ? action.payload : [null],
      };

    default:
      return state;
  }
};
export default cashPaidReducer;
