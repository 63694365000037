import {
	ADD_ISSUE,
	GET_ISSUES,
	UPDATE_ISSUE,
	CHANGE_ISSUE_STATUS,
} from "./issueType";

const initialState = {
	issues: [],
};

const issueReducer = (state = initialState, action) => {
	let index, allIssues;
	switch (action.type) {
		case ADD_ISSUE:
			allIssues = [...state.issues];
			if (allIssues?.[0] === null) allIssues = [];
			allIssues.unshift(action.payload.data.issue);

			return {
				...state,
				issues: allIssues,
			};
		case GET_ISSUES:
			return {
				...state,
				issues:
					action.payload.data.issues.length > 0
						? action.payload.data.issues
						: [null],
			};
		case UPDATE_ISSUE:
			allIssues = [...state.issues];
			index = allIssues.findIndex(
				(row) => row._id === action.payload.data.issue._id
			);
			allIssues[index] = action.payload.data.issue;
			return {
				...state,
				issues: allIssues,
			};

		case CHANGE_ISSUE_STATUS:
			allIssues = [...state.issues];
			index = allIssues.findIndex(
				(row) => row._id === action.payload.data.issue._id
			);
			allIssues[index] = action.payload.data.issue;
			return {
				...state,
				issues: allIssues,
			};
		default:
			return state;
	}
};
export default issueReducer;
