import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import {
  addCashPaid,
  updateCashPaid,
} from "../../../../../redux/cashPaid/cashPaidAction";

import BannerHeading from "../../../../../_common/BannerHeading";
import CircleLoader from "../../../../../_common/CircleLoader";
import UpdateCashPaidForm from "../UpdateCashPaid/UpdateCashPaidForm";
import AddCashPaidForm from "./AddCashPaidForm";

function AddCashPaid(props) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSubmit = (values) => {
    setLoading(true);

    let data = { ...values };

    data.driverId = data.driver[0].id;

    delete data.driver;

    if (id) {
      data._id = id;
      dispatch(updateCashPaid(data))
        .then(() => {
          setLoading(false);
          props.history.push("/cash-paid");
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      dispatch(addCashPaid(data))
        .then(() => {
          setLoading(false);
          props.history.push("/cash-paid");
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="dashboard-main">
      <Grid align={ALIGNMENT.center}>
        <Cell span={[4, 8, 12]}>
          <BannerHeading title={`${id ? "Update" : "Add"}  Cash Paid`} />
          <div className="form-box">
            {loading && <CircleLoader />}
            <AddCashPaidForm isLoading={false} onSubmit={handleSubmit} />
            {id && <UpdateCashPaidForm />}
          </div>
        </Cell>
      </Grid>
    </div>
  );
}

export default withRouter(AddCashPaid);
