import { config } from "../../config";
import {
  ADD_EXPENSE,
  GET_EXPENSE,
  UPDATE_EXPENSE,
  INCREMENT_PAGE,
  CLEAR_EXPENSE,
  TOTAL_EXPENSE,
  DELETE_EXPENSE,
} from "./expenseType";

const initialState = {
  expense: [],
  totalExpense: [],
  page: 1,
  count: 0,
  more: true,
  reset: 0,
};

const expenseReducer = (state = initialState, action) => {
  let index, allExpense;
  switch (action.type) {
    case ADD_EXPENSE:
      allExpense = [...state.expense];
      if (allExpense?.[0] === null) allExpense = [];
      allExpense.unshift(action.payload);

      return {
        ...state,
        expense: allExpense,
      };

    case GET_EXPENSE:
      const { count, expenses } = action.payload;

      if (state.page === 1) allExpense = [...expenses];
      else allExpense = [...state.expense, ...expenses];

      return {
        ...state,
        count,
        more: expenses.length == config.EXPENSE_PAGE_SIZE,

        expense: allExpense?.length > 0 ? allExpense : [null],
      };

    case UPDATE_EXPENSE:
      allExpense = [...state.expense];
      index = allExpense.findIndex((row) => row._id === action.payload._id);
      allExpense[index] = action.payload;
      return {
        ...state,
        expense: allExpense,
      };
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case CLEAR_EXPENSE:
      return {
        ...state,
        page: 1,
        count: 0,
        more: true,
        expense: [],
        reset: state.reset + 1,
      };
    case TOTAL_EXPENSE:
      return {
        ...state,
        totalExpense: action.payload ? action.payload : [null],
      };

    case DELETE_EXPENSE:
      allExpense = [...state.expense];
      allExpense = allExpense.filter((item) => item._id !== action.payload);
      allExpense = allExpense.length > 0 ? allExpense : [null];

      return {
        ...state,
        expense: allExpense,
      };
    default:
      return state;
  }
};
export default expenseReducer;
