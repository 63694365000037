import {
  StyledRoot,
  StyledTable,
  StyledTableHead,
  StyledTableBody,
  StyledTableHeadRow,
  StyledTableBodyRow,
  StyledTableHeadCell,
  StyledTableBodyCell,
} from "baseui/table-semantic";
import { useSelector } from "react-redux";

const MonthlyExpense = () => {
  const { expenses } = useSelector((state) => state.expense.totalExpense);
  return (
    <div className="form-box">
      <h1>Total Expense</h1>
      <StyledRoot>
        {expenses?.length === 0 ? (
          <div>No results found</div>
        ) : (
          <StyledTable>
            <StyledTableHead>
              <StyledTableHeadRow>
                <StyledTableHeadCell>Driver Name</StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Fuel
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Wash
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Water
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Parking
                </StyledTableHeadCell>

                <StyledTableHeadCell style={{ textTransform: "capitalize" }}>
                  Others
                </StyledTableHeadCell>

                <StyledTableHeadCell>Total Expense</StyledTableHeadCell>
              </StyledTableHeadRow>
            </StyledTableHead>
            <StyledTableBody>
              {expenses &&
                expenses.length !== 0 &&
                expenses?.map((data) => {
                  return (
                    <StyledTableBodyRow>
                      <StyledTableBodyCell
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {data.driver}
                      </StyledTableBodyCell>

                      <StyledTableBodyCell>
                        € {data.exps?.[0]?.price?.toFixed(2)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        € {data.exps?.[1]?.price?.toFixed(2)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        € {data.exps?.[2].price?.toFixed(2)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        € {data.exps?.[3]?.price?.toFixed(2)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        € {data.exps?.[4]?.price?.toFixed(2)}
                      </StyledTableBodyCell>
                      <StyledTableBodyCell>
                        € {data?.total?.toFixed(2)}
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  );
                })}
            </StyledTableBody>
          </StyledTable>
        )}
      </StyledRoot>
    </div>
  );
};

export default MonthlyExpense;
