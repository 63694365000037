import http, { setJWT } from "./http";

const API_URL = "/auth";

const authService = {
	login: (email, password) => {
		return http
			.post(API_URL + "/login", {
				email,
				password,
			})
			.then((response) => {
				if (response.data.data.user.can_change_password)
					localStorage.setItem("can_change_password", true);
				if (response.data.data.token) {
					localStorage.setItem(
						"token",
						`Bearer ${response.data.data.token}`
					);
					localStorage.setItem(
						"user",
						JSON.stringify(response.data.data.user)
					);
				}
				return response.data;
			});
	},

	logout: () => {
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		localStorage.removeItem("can_change_password");
	},

	authHeader: () => {
		const token = localStorage.getItem("token");
		if (token) {
			return { Authorization: token };
		} else {
			return {};
		}
	},

	currentUser: () => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			return user;
		} else {
			return null;
		}
	},

	apiURL: () => {
		let user = JSON.parse(localStorage.getItem("user"));
		if (user.role === "driver") return "user";
		if (user.role === "admin" || user.role === "manager") return "admin";
		else return user.role;
	},

	changePassword: (password) => {
		setJWT();

		return http
			.post("user/changePassword", { password })
			.then((response) => {
				if (response.data.data.token) 
					localStorage.setItem(
						"token",
						`Bearer ${response.data.data.token}`
					);

				return response.data;
			});
	},
	sendActivity: () => {
		setJWT();
		return http.get("activity/set").then((response) => {
			return response.data;
		});
	},
};

export default authService;
