let error, success;
const ToasterService = {
	showSuccessToaster: function (data) {
		success(data);
	},
	showErrorToaster: function (data) {
		error(data);
	},

	subscribeToToaster: function (
		success_subscribe_callBack,
		error_subscribe_callBack
	) {
		success = success_subscribe_callBack;
		error = error_subscribe_callBack;
	},
};
export default ToasterService;
