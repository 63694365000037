import authService from "./auth.service";
import http, { setJWT, setMultiPart } from "./http";
import { getOpenInvoices } from "./invoice.service";

const adminService = {
  getInvoiceForAdmin: (data) => {
    data.open = getOpenInvoices();

    setJWT();
    return http.post(authService.apiURL() + "/getInvoices", data);
  },

  getDrivers: () => {
    setJWT();
    return http.get(authService.apiURL() + "/getDrivers");
  },

  getSearchedDrivers: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/getSearchedDrivers", data);
  },

  changeStatus: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/changeStatus", data);
  },

  addDriver: (data) => {
    return http.post(authService.apiURL() + "/addDriver", data);
  },

  updateDriver: (data) => {
    return http.post(authService.apiURL() + "/updateDriver", data);
  },

  imageUpload: async (formdata) => {
    setMultiPart();
    const result = await http.post("image/upload", formdata);
    return result;
  },
  multiImageUpload: (formdata) => {
    setMultiPart();
    return http.post("image/uploads", formdata);
  },

  sendEmailNotify: (data) => {
    data = { _id: data };

    setJWT();
    return http.post(authService.apiURL() + "/sendEmailNotify", data);
  },

  getSearchedInvoices: (date, driver, status, page, draft) => {
    const convertDate = (date) => {
      let d = new Date(date),
        dd = d.getDate(),
        mm = d.getMonth() + 1,
        yy = d.getFullYear();

      return `${yy}-${mm > 9 ? mm : "0" + mm}-${dd > 9 ? dd : "0" + dd}`;
    };

    let id = null,
      end_date = null,
      start_date = null,
      status_filter = null;

    if (driver.length === 1) id = driver[0].id;
    if (status.length === 1) status_filter = status[0].id;
    if (date.length === 2) {
      start_date = convertDate(date[0]);
      end_date = convertDate(date[1]);
    }

    let data = {
      id,
      page,
      end_date,
      start_date,
      status_filter,
      open: getOpenInvoices(),
      draft,
    };

    setJWT();
    return http.post(authService.apiURL() + "/searchByDate", data);
  },

  getSearchedInvoicesById: (id, page) => {
    let data = { page, search_term: id, open: getOpenInvoices() };

    setJWT();
    return http.post(authService.apiURL() + "/searchById", data);
  },

  getEarnings: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/getAllEarnings", data);
  },

  addEarnings: (data) => {
    let partner_data = [];
    data.partner_details.forEach(
      ({
        tips,
        cash,
        netto,
        brutto,
        is_cash,
        partner,
        total_trips,
        total_earnings,
      }) => {
        if (total_earnings > 0)
          partner_data.push({
            tips,
            cash,
            netto,
            brutto,
            is_cash,
            trips: total_trips,
            earning: total_earnings,
            partner_id: partner[0].id,
          });
      }
    );

    let payload = {
      wash: data.wash,
      tips: data.tips,
      water: data.water,
      extras: data.extras,
      is_live: data.is_live,
      year: data.year[0].id,
      month: data.month[0].id,
      cash: data.private_cash,
      utilities: data.utilities,
      driver_id: data.driver[0].id,
      earnings_details: partner_data,
      paid_by_cash: data.paid_by_cash,
      remaining: data.total_remaining,
      paid_in_bank: data.payed_by_bank,
      private_trips: data.private_trips,
      total_revenue: data.total_revenue,
      fuel_in_price: data.fuel_in_price,
      fuel_in_liters: data.fuel_in_liters,
      total_earnings: data.total_earnings,
      parking_tickets: data.parking_tickets,
      private_revenue: data.private_revenue,
      private_earnings: data.private_earnings,
      total_revenue_netto: data.total_revenue_netto,
      total_cash_received: data.total_cash_received,
      cash_paid: data.cash_paid,
      loan_payment: data.loan_payment,
      earnings: data.earnings,
      fine: data.fine,
    };

    setJWT();
    return http.post(authService.apiURL() + "/addEarning", payload);
  },

  updateEarning: (data) => {
    let partner_data = [];
    data.partner_details.forEach(
      ({
        tips,
        cash,
        netto,
        brutto,
        is_cash,
        partner,
        total_trips,
        total_earnings,
      }) => {
        if (total_earnings > 0)
          partner_data.push({
            tips,
            cash,
            netto,
            brutto,
            is_cash,
            trips: total_trips,
            earning: total_earnings,
            partner_id: partner[0].id,
          });
      }
    );

    let payload = {
      _id: data.id,
      wash: data.wash,
      tips: data.tips,
      water: data.water,
      extras: data.extras,
      is_live: data.is_live,
      year: data.year[0].id,
      cash: data.private_cash,
      month: data.month[0].id,
      utilities: data.utilities,
      driver_id: data.driver[0].id,
      earnings_details: partner_data,
      paid_by_cash: data.paid_by_cash,
      remaining: data.total_remaining,
      paid_in_bank: data.payed_by_bank,
      private_trips: data.private_trips,
      total_revenue: data.total_revenue,
      fuel_in_price: data.fuel_in_price,
      total_earnings: data.total_earnings,
      fuel_in_liters: data.fuel_in_liters,
      parking_tickets: data.parking_tickets,
      private_revenue: data.private_revenue,
      private_earnings: data.private_earnings,
      total_revenue_netto: data.total_revenue_netto,
      total_cash_received: data.total_cash_received,
      cash_paid: data.cash_paid,
      loan_payment: data.loan_payment,
      earnings: data.earnings,
      fine: data.fine,
    };

    setJWT();
    return http.post(authService.apiURL() + "/updateEarning", payload);
  },

  getPrivateEarnings: (year) => {
    let data = { year };

    setJWT();
    return http.post(authService.apiURL() + "/getStat", data);
  },

  getDriverPrivateEarnings: (data) => {
    setJWT();
    return http.post(authService.apiURL() + "/getDriverStats", data);
  },
};

export default adminService;
