import adminService from "../../_services/admin.service";
import ToasterService from "../../_services/toasterService";
import { SET_MESSAGE } from "../message/messageTypes";
import { ADD_DRIVER, GET_DRIVER, UPDATE_DRIVER } from "./driverType";

export const addDriver = (data) => (dispatch) => {
  return adminService.addDriver(data).then(
    (response) => {
      dispatch({
        type: ADD_DRIVER,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });
      ToasterService.showSuccessToaster("Driver added successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const updateDriver = (data) => (dispatch) => {
  return adminService.updateDriver(data).then(
    (response) => {
      dispatch({
        type: UPDATE_DRIVER,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      ToasterService.showSuccessToaster("Driver updated successfully");
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};
export const getDrivers = () => (dispatch) => {
  return adminService.getDrivers().then(
    (response) => {
      dispatch({
        type: GET_DRIVER,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_DRIVER,
        payload: { data: [] },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const getSearchedDrivers = (data) => (dispatch) => {
  return adminService.getSearchedDrivers(data).then(
    (response) => {
      dispatch({
        type: GET_DRIVER,
        payload: response.data,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: GET_DRIVER,
        payload: { data: [] },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
