import {
  ADD_USER,
  DELETE_USER,
  GET_USERS,
  UPDATE_PASSWORD,
  UPDATE_USER,
} from "./userType";

const initialState = {
  users: [],
};

const userReducer = (state = initialState, action) => {
  let index, allUsers;
  switch (action.type) {
    case ADD_USER:
      allUsers = [...state.users];
      if (allUsers?.[0] === null) allUsers = [];
      allUsers.unshift(action.payload.data.user);

      return {
        ...state,
        users: allUsers,
      };
    case GET_USERS:
      return {
        ...state,
        users:
          action.payload.data.users.length > 0
            ? action.payload.data.users
            : [null],
      };
    case UPDATE_USER:
      allUsers = [...state.users];
      index = allUsers.findIndex(
        (row) => row._id === action.payload.data.user._id
      );
      allUsers[index] = action.payload.data.user;
      return {
        ...state,
        users: allUsers,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((item) => item._id !== action.payload),
      };
    case UPDATE_PASSWORD:
      allUsers = [...state.users];
      index = allUsers.findIndex(
        (row) => row._id === action.payload.data.user._id
      );
      allUsers[index] = action.payload.data.user;
      return {
        ...state,
        users: allUsers,
      };
    default:
      return state;
  }
};
export default userReducer;
